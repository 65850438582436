import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'dropzone', {
  instruction: 'Arrastra aquí el archivo o presiona ',
  here: 'aquí',
  drop: 'Deja los archivos aqui',
  delete: 'Eliminar',
  download: 'Descargar',
  maximumSize: 'Peso máx: {{ size }}Mb',
  formats: 'Formatos',
  exceedsMaximumSize: 'El archivo excede el peso máximo soportado',
  invalidFormat: 'El archivo no cumple con los formatos soportados',
});

export default i18n;
