import { createContext } from 'react';
import { MenuOptionType, MenuStateType } from './types';

const menuState: MenuStateType = {
  isOpen: false,
  target: null,
  options: [],
  setOptions: (options: MenuOptionType[]) => {},
  setTarget: (target: HTMLElement | null) => {},
  openMenu: (target: HTMLElement | null, options: MenuOptionType[]) => {},
};

export const MenuContext = createContext(menuState);
