import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupplierAddress, SupplierContact, SupplierStore } from 'src/types/suppliers';
import { RootState } from '../store';

const initialState: SupplierStore = {
  contactId: null,
  addressId: null,
  contactDialogIsOpen: false,
  addressDialogIsOpen: false,
};

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    openAddressDetail: (state: SupplierStore, action: PayloadAction<SupplierAddress['id'] | null>) => {
      state.addressId = action.payload;
      state.addressDialogIsOpen = true;
    },
    closeAddressDetail: (state: SupplierStore) => {
      state.addressId = null;
      state.addressDialogIsOpen = false;
    },
    openContactDetail: (state: SupplierStore, action: PayloadAction<SupplierContact['id'] | null>) => {
      state.contactId = action.payload;
      state.contactDialogIsOpen = true;
    },
    closeContactDetail: (state: SupplierStore) => {
      state.contactId = null;
      state.contactDialogIsOpen = false;
    },
  },
});

export const actions = supplierSlice.actions;

export const supplierStore = (state: RootState) => state.supplier;

export default supplierSlice.reducer;
