import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DocumentNotification, DocumentNotificationsStore } from 'src/types/documentNotifications';

const initialState: DocumentNotificationsStore = {
  documentNotifications: null,
  isDocumentModalOpen: false,
  isSendModalOpen: false,
};

export const documentNotificationsSlice = createSlice({
  name: 'documentNotifications',
  initialState,
  reducers: {
    openDocumentModal: (state: DocumentNotificationsStore, action: PayloadAction<DocumentNotification | null>) => {
      state.documentNotifications = action.payload;
      state.isDocumentModalOpen = true;
    },
    closeDocumentModal: (state: DocumentNotificationsStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.documentNotifications = null;
      state.isDocumentModalOpen = false;
    },
    openSendModal: (state: DocumentNotificationsStore, action: PayloadAction<DocumentNotification | null>) => {
      state.documentNotifications = action.payload;
      state.isSendModalOpen = true;
    },
    closeSendModal: (state: DocumentNotificationsStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.documentNotifications = null;
      state.isSendModalOpen = false;
    },
  },
});

export const actions = documentNotificationsSlice.actions;

export const documentNotificationsStore = (state: RootState) => state.documentNotifications;

export default documentNotificationsSlice.reducer;
