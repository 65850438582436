// import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { ThemeContext } from './ThemeContext';
import { CustomThemeProps, ThemeVariantsType } from './types';

const ThemeProvider = ({ children }: CustomThemeProps) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeVariantsType>('light');

  const changeTheme = (theme: ThemeVariantsType) => {
    setCurrentTheme(theme);
    localStorage.setItem('theme', theme);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
        changeTheme,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
