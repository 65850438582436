import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions, globalStore } from 'src/reducers/global/global';

const Snackbar = () => {
  const { snackbar } = useSelector(globalStore);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(globalActions.closeSnackbar());
  };

  return (
    <MuiSnackbar
      open={snackbar?.show}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
      {snackbar?.show ? (
        <Alert severity={snackbar?.status} sx={{ width: '100%' }} variant="outlined">
          {snackbar?.message || ''}
        </Alert>
      ) : undefined}
    </MuiSnackbar>
  );
};

export default Snackbar;
