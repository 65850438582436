import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DeliveryNoteList, DeliveryNotesStore } from 'src/types/deliveryNotes';

const initialState: DeliveryNotesStore = {
  deliveryNotes: null,
  isEditModalOpen: false,
  isDetailModalOpen: false,
  isSendModalOpen: false,
};

export const deliveryNotesSlice = createSlice({
  name: 'deliveryNotes',
  initialState,
  reducers: {
    openEditModal: (state: DeliveryNotesStore, action: PayloadAction<DeliveryNoteList | null>) => {
      state.deliveryNotes = action.payload ? [action.payload] : null;
      state.isEditModalOpen = true;
    },
    closeEditModal: (state: DeliveryNotesStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.deliveryNotes = null;
      state.isEditModalOpen = false;
    },
    openDetailModal: (state: DeliveryNotesStore, action: PayloadAction<DeliveryNoteList | null>) => {
      state.deliveryNotes = action.payload ? [action.payload] : null;
      state.isDetailModalOpen = true;
    },
    closeDetailModal: (state: DeliveryNotesStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.deliveryNotes = null;
      state.isDetailModalOpen = false;
    },
    openSendModal: (state: DeliveryNotesStore, action: PayloadAction<DeliveryNoteList | DeliveryNoteList[] | null>) => {
      if (Array.isArray(action.payload)) state.deliveryNotes = action.payload;
      else state.deliveryNotes = action.payload ? [action.payload] : null;
      state.isSendModalOpen = true;
    },
    closeSendModal: (state: DeliveryNotesStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.deliveryNotes = null;
      state.isSendModalOpen = false;
    },
  },
});

export const actions = deliveryNotesSlice.actions;

export const deliveryNotesStore = (state: RootState) => state.deliveryNotes;

export default deliveryNotesSlice.reducer;
