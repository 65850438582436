import styled from 'styled-components';
import { DialogTitle, IconButton, Typography, Dialog as MaterialDialog } from '@mui/material';

export const Title = styled(Typography)`
  padding-right: 16px;
`;

export const Dialog = styled(MaterialDialog)`
  .MuiPaper-root {
    max-width: 85%;
    min-width: 240px;
    overflow: hidden;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    .MuiPaper-root {
      max-width: 100%;
      min-width: 240px;
      margin: 16px;
    }
  }
`;

export const DialogHeader = styled(DialogTitle)`
  padding: 24px 24px 16px 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 16px 16px 8px 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 16px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;

  & svg {
    font-size: 2.1rem;
  }

  &:hover {
    background-color: transparent;
  }

  & path {
    fill: ${({ theme }) => theme.palette.grey[300]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    top: 8px;
    right: 8px;

    & svg {
      font-size: 2rem;
    }
  }
`;

export const DialogContent = styled.div`
  padding: 0 24px 16px 24px;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.background.default};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey[400]};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.grey[500]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 16px;
  }
`;

export const DialogFooter = styled.div`
  padding: 16px 24px;
  box-shadow: rgba(0, 0, 0, 0.2) -2px 2px 10px 1px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 16px;
  }
`;
