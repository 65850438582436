import styled from 'styled-components';
import { SCREEN_MAX_WIDTH } from 'src/utils/common//constants';
import { Box } from '@mui/material';

export const PageContainer = styled(Box)<{ disablePadding: boolean }>`
  padding: ${({ disablePadding }) => (disablePadding ? 0 : 50)}px;
  margin-top: var(--header-height, 64);
  height: calc(100vh - var(--header-height, 64));
  height: calc(100dvh - var(--header-height, 64));
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.background.default};

  & > div:not(#global-loader) {
    width: 100%;
    max-width: ${SCREEN_MAX_WIDTH}px;
    margin: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.background.default};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey[400]};
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.grey[500]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    padding: ${({ disablePadding }) => (disablePadding ? 0 : 32)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: ${({ disablePadding }) => (disablePadding ? 0 : 16)}px;
  }
`;
