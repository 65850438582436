import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'confirm',
  {
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },
  true,
  false,
);

export default i18n;
