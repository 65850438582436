import { Menu, MenuItem, Typography } from '@mui/material';
import { useProfile } from './useProfile';
import { AccountContainer, ProfileIcon } from './styles';
import { useTranslation } from 'react-i18next';
import './i18n';

const Profile = () => {
  const { t } = useTranslation('profile');
  const { user, anchorElem, handleContextMenu, handleClose, handleLogout, handleSettingsClick } = useProfile();

  const getInitials = () => `${user?.firstName?.charAt(0) || ''}${user?.lastName?.charAt(0) || ''}`.toUpperCase();

  return (
    <>
      <AccountContainer onClick={handleContextMenu}>
        <ProfileIcon>
          <Typography variant="h6" color="inherit">
            {getInitials()}
          </Typography>
        </ProfileIcon>
        <Typography color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }}>
          {user?.firstName}
        </Typography>
      </AccountContainer>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElem}
        keepMounted
        open={!!anchorElem}
        onClose={handleClose}
        slotProps={{ paper: { style: { width: 160, right: 0 } } }}>
        <MenuItem sx={{ padding: '10px 16px' }} onClick={handleSettingsClick}>
          <Typography noWrap> {t('settings')}</Typography>
        </MenuItem>
        <MenuItem sx={{ padding: '10px 16px' }} onClick={handleLogout}>
          <Typography noWrap> {t('logout')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Profile;
