import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DocumentList, DocumentManagerStore } from 'src/types/documentManager';

const initialState: DocumentManagerStore = {
  document: null,
  isEditModalOpen: false,
};

export const documentManagerSlice = createSlice({
  name: 'documentManager',
  initialState,
  reducers: {
    openEditModal: (state: DocumentManagerStore, action: PayloadAction<DocumentList | null>) => {
      state.document = action.payload;
      state.isEditModalOpen = true;
    },
    closeEditModal: (state: DocumentManagerStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.document = null;
      state.isEditModalOpen = false;
    },
  },
});

export const actions = documentManagerSlice.actions;

export const documentManagerStore = (state: RootState) => state.documentManager;

export default documentManagerSlice.reducer;
