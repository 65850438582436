import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';
import { RoleControllerProps } from './types';

const RoleController = ({ children, redirect, requiredRole }: RoleControllerProps) => {
  const { user } = useSelector(authStore);

  if (user && user.role >= requiredRole) return <>{children}</>;
  else if (redirect) return <Navigate to={ROUTES.ADMIN} />;
  else return null;
};

export default RoleController;
