import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientAddress, ClientContact, ClientStore } from 'src/types/clients';
import { RootState } from '../store';

const initialState: ClientStore = {
  addressId: null,
  contactId: null,
  contactDialogIsOpen: false,
  addressDialogIsOpen: false,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    openAddressDetail: (state: ClientStore, action: PayloadAction<ClientAddress['id'] | null>) => {
      state.addressId = action.payload;
      state.addressDialogIsOpen = true;
    },
    closeAddressDetail: (state: ClientStore) => {
      state.addressId = null;
      state.addressDialogIsOpen = false;
    },
    openContactDetail: (state: ClientStore, action: PayloadAction<ClientContact['id'] | null>) => {
      state.contactId = action.payload;
      state.contactDialogIsOpen = true;
    },
    closeContactDetail: (state: ClientStore) => {
      state.contactId = null;
      state.contactDialogIsOpen = false;
    },
  },
});

export const actions = clientSlice.actions;

export const clientStore = (state: RootState) => state.client;

export default clientSlice.reducer;
