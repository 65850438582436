import { Autocomplete, AutocompleteChangeReason, Chip, Typography } from '@mui/material';
import Input from '../Input/Input';
import { DefaultOptionType, SelectProps } from './types';
import { SyntheticEvent } from 'react';

const Select = <OptionType extends DefaultOptionType>({
  id,
  options,
  disablePortal = true,
  sx,
  sxInput,
  label,
  includeErrorSpace,
  error,
  onChange,
  onClear,
  getOptionDisabled,
  value,
  size = 'medium',
  multiple,
  limitTags,
  renderOption,
  isOptionEqualToValue,
  disableCloseOnSelect,
  ...props
}: SelectProps<OptionType>) => {
  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    value: OptionType | null,
    reason: AutocompleteChangeReason,
  ) => {
    e.stopPropagation();
    if (reason === 'clear' && onClear) onClear();
    if ((reason === 'selectOption' || reason === 'removeOption') && value) onChange(value);
  };

  return (
    <Autocomplete<OptionType>
      disablePortal={disablePortal}
      id={id}
      options={options}
      getOptionLabel={o => o.name}
      getOptionDisabled={getOptionDisabled}
      sx={sx}
      onChange={handleChange}
      value={value}
      size={size}
      //@ts-expect-error Bad typing on material autocomplete
      disableClearable={!onClear}
      {...(multiple && { multiple })}
      {...(renderOption && { renderOption })}
      {...(isOptionEqualToValue && { isOptionEqualToValue })}
      {...(limitTags && { limitTags })}
      disableCloseOnSelect={disableCloseOnSelect}
      {...props}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limit = limitTags || 1;
        return (
          <>
            {value.slice(0, limit).map((option, index) => (
              <Chip size="small" {...getTagProps({ index })} key={index} label={option.name} />
            ))}

            <Typography sx={{ ml: 1 }}> {numTags > limit && ` +${numTags - limit}`}</Typography>
          </>
        );
      }}
      renderInput={params => (
        <Input
          variant="outlined"
          {...params}
          label={label}
          includeErrorSpace={includeErrorSpace}
          helperText={error}
          error={!!error}
        />
      )}
    />
  );
};

export default Select;
