import { Header, ConfirmDialog } from 'src/components';
import { PageContainer } from './styles';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';
import { globalStore } from 'src/reducers/global/global';
import { Box } from '@mui/material';

const PrivateLayout = () => {
  const { pathname } = useLocation();
  const { user } = useSelector(authStore);
  const { alert } = useSelector(globalStore);

  if (!user) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return (
    <Box>
      <Header />
      <PageContainer component="main" disablePadding={pathname === ROUTES.PAYMENT_MANAGER}>
        <Outlet />
      </PageContainer>
      <ConfirmDialog
        open={alert.show}
        title={alert.title}
        message={alert.message}
        cancelLabel={alert.cancelLabel}
        confirmLabel={alert.confirmLabel}
        onCancel={alert.onCancel}
        onConfirm={alert.onConfirm}
      />
    </Box>
  );
};

export default PrivateLayout;
