import styled from 'styled-components';

export const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  cursor: pointer;
`;

export const ProfileIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 32px;
    height: 32px;
  }
`;
