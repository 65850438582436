import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ConfirmDialogProps } from './types';
import { useTranslation } from 'react-i18next';
import './i18n';
import { ActionsContainer, Dialog } from './styles';

const ConfirmDialog = ({
  open,
  title,
  message,
  confirmLabel,
  cancelLabel,
  onCancel,
  onConfirm,
}: ConfirmDialogProps) => {
  const { t } = useTranslation('confirm');
  return (
    <Dialog open={open} keepMounted aria-describedby="confirm-dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <ActionsContainer>
        <Button onClick={onCancel}>{cancelLabel || t('cancel')}</Button>
        <Button variant="contained" onClick={onConfirm}>
          {confirmLabel || t('confirm')}
        </Button>
      </ActionsContainer>
    </Dialog>
  );
};
export default ConfirmDialog;
