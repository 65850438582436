import * as yup from 'yup';

export const validationSchema = yup.object({
  smtp: yup.mixed().required('smtp.required'),
  to: yup.array().of(yup.string().required()).min(1, 'to.min').required(),
  subject: yup.string().required('subject.required'),
  body: yup.string(),
  isProgrammed: yup.boolean().required(),
  programDate: yup.mixed().when('isProgrammed', {
    is: true,
    then: yup.mixed().required('programDate.required'),
    otherwise: yup.mixed(),
  }),
});

export type SendEmailForm = yup.InferType<typeof validationSchema>;
