import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'header',
  {
    orders: 'Pedidos',
    invoices: 'Facturas',
    manager: 'Gestor',
    reports: 'Reportes',
    'kilos-report': 'Intrastad',
    'payment-manager': 'Pagos',
    documents: 'Documentos',
    'delivery-notes': 'Albaranes',
    settlements: 'Liquidaciones',
    boxesControl: 'Cajas',
    documentNotifications: 'Notificaciones',
    users: 'Usuarios',
    clients: 'Clientes',
    suppliers: 'Proveedores',
    smpts: 'SMTP',
    configuration: 'Configuración',
    logout: 'Salir',
  },
  true,
  false,
);

export default i18n;
