import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SettlementList, SettlementsStore } from 'src/types/settlements';

const initialState: SettlementsStore = {
  settlements: null,
  isEditModalOpen: false,
  isDetailModalOpen: false,
  isSendModalOpen: false,
};

export const settlementsSlice = createSlice({
  name: 'settlements',
  initialState,
  reducers: {
    openEditModal: (state: SettlementsStore, action: PayloadAction<SettlementList | null>) => {
      state.settlements = action.payload ? [action.payload] : null;
      state.isEditModalOpen = true;
    },
    closeEditModal: (state: SettlementsStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.settlements = null;
      state.isEditModalOpen = false;
    },
    openDetailModal: (state: SettlementsStore, action: PayloadAction<SettlementList | null>) => {
      state.settlements = action.payload ? [action.payload] : null;
      state.isDetailModalOpen = true;
    },
    closeDetailModal: (state: SettlementsStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.settlements = null;
      state.isDetailModalOpen = false;
    },
    openSendModal: (state: SettlementsStore, action: PayloadAction<SettlementList | SettlementList[] | null>) => {
      if (Array.isArray(action.payload)) state.settlements = action.payload;
      else state.settlements = action.payload ? [action.payload] : null;
      state.isSendModalOpen = true;
    },
    closeSendModal: (state: SettlementsStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.settlements = null;
      state.isSendModalOpen = false;
    },
  },
});

export const actions = settlementsSlice.actions;

export const settlementsStore = (state: RootState) => state.settlements;

export default settlementsSlice.reducer;
