import styled from 'styled-components';
import { LogoSize } from './types';

export const LogoContainer = styled.a<{ size?: LogoSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: ${({ size }) => (size === 'small' ? 60 : size === 'medium' ? 75 : 90)}px;
  min-width: ${({ size }) => (size === 'small' ? 60 : size === 'medium' ? 75 : 90)}px;
`;

export const LogoImg = styled.img`
  height: 100%;
  object-fit: cover;
  margin-top: -8px;
`;
