/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeOptions } from '@mui/material';

const primary = '#F7875D';
const secondary = '#29343D';
const success = '#5f9d5c';

const light = {
  main: '#f3f4f6',
  lighter: '#fff',
  border: '#e5e7eb',
  lightText: '#a2a2a2',
  text: '#222',
  textDark: '#141414',
};

export const lightThemeOptions: ThemeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: light.text,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.6,
    },
    subtitle2: {
      fontSize: '0.9rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
    },
    body2: {
      fontSize: 12,
      color: '#a2a2a2',
    },
  },
  palette: {
    primary: {
      main: primary,
      contrastText: light.lighter,
    },
    secondary: {
      main: secondary,
    },
    background: {
      default: light.main,
      paper: light.lighter,
    },
    text: {
      primary: light.text,
    },
    success: {
      main: success,
    },
    divider: light.border,
    grey: {
      '200': '#ededed',
      '300': '#d5d5d5',
      '500': '#a2a2a2',
    },
  },
  status: {
    pending: '#b00',
    invoiced: '#00b',
    'commission-invoice-missing': '#cc0',
    'commission-payment-missing': '#d60',
    paid: '#0b0',
    sent: '#0b0',
    scheduled: '#d60',
    canceled: '#b00',
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: light.main,
          height: '64px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
          fontSize: '1rem',
          padding: '8px',
          borderBottomColor: light.border,
          background: light.lighter,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: light.lighter,
          backgroundColor: secondary,
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          padding: '6px 16px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-sizeSmall': {
            fontSize: '1rem',
          },
          '&.MuiButton-sizeMedium': {
            fontSize: '1rem',
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '1.15rem',
          },
          '&.MuiButton-text': {
            paddingLeft: 0,
            paddingRight: 0,
          },
          '&.MuiButton-outlined': {
            borderWidth: 2,
          },
          '&.MuiButton-text:hover': {
            background: 'transparent',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& > div': {
            backgroundColor: light.lighter,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'linear-gradient(rgba(255,255,255,0.1),rgba(255,255,255,0.05))',
          borderRight: 0,
          boxSizing: 'border-box',
          width: 260,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: light.lighter,
            padding: '4px',
          },
          li: {
            borderRadius: '4px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label, & label.Mui-focused': {
            color: light.lightText,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label, & label.Mui-focused': {
            color: light.lightText,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& > span': {
            fontSize: '1.15rem',
          },
        },
      },
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  ...lightThemeOptions,
};
