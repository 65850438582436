/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, Suspense } from 'react';
import GlobalLoader from '../GlobalLoader/GlobalLoader';

const LazyLoader =
  <P extends object>(Component: ComponentType<P>) =>
  (props: any) =>
    (
      <Suspense fallback={<GlobalLoader loading />}>
        <Component {...props} />
      </Suspense>
    );

export default LazyLoader;
