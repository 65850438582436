import { LogoContainer, LogoImg } from './styles';
import LogoBlack from 'src/assets/logo_black.png';
import LogoWhite from 'src/assets/logo_white.png';
import LogoShortVersion from 'src/assets/logo_small.png';
import { LogoProps, LogoVersion } from './types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from 'styled-components';

const Logo = ({ size = 'small', color = 'black', version = 'full', className }: LogoProps) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const logo: { [k in LogoVersion]: string } = {
    reduced: color === 'black' ? LogoShortVersion : LogoShortVersion,
    full: color === 'black' ? LogoBlack : LogoWhite,
    auto: isSmDown
      ? color === 'black'
        ? LogoShortVersion
        : LogoShortVersion
      : color === 'black'
      ? LogoBlack
      : LogoWhite,
  };

  return (
    <LogoContainer href={'/'} size={size} className={['logo', className].join(' ')}>
      <LogoImg src={logo[version]} alt="logo" />
    </LogoContainer>
  );
};

export default Logo;
