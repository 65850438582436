import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client, ClientsStore } from 'src/types/clients';
import { RootState } from '../store';

const initialState: ClientsStore = {
  clientId: null,
  isOpen: false,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    openClientDetail: (state: ClientsStore, action: PayloadAction<Client['id'] | null>) => {
      state.clientId = action.payload;
      state.isOpen = true;
    },
    closeClientDetail: (state: ClientsStore) => {
      state.clientId = null;
      state.isOpen = false;
    },
  },
});

export const actions = clientsSlice.actions;

export const clientsStore = (state: RootState) => state.clients;

export default clientsSlice.reducer;
