import styled from 'styled-components';

export const DateContainer = styled.div`
  .rs-picker-daterange .rs-picker-toggle {
    box-shadow: none;
    border-color: #d1d1d1;

    &:hover {
      border-color: #d1d1d1;
    }
  }

  && .rs-picker-toggle-placeholder {
    font-size: 1rem;
  }

  && .rs-picker-toggle-textbox,
  && .rs-picker-toggle-value {
    font-size: 1rem;
    color: ${({ theme }) => theme.typography.body1.color};
  }
`;
