import { useState } from 'react';
import { MenuContext } from './MenuContext';
import { MenuContextProps, MenuOptionType } from './types';

const MenuProvider = ({ children }: MenuContextProps) => {
  const [menuOptions, setMenuOptions] = useState<MenuOptionType[]>([]);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

  const setOptions = (options: MenuOptionType[]) => setMenuOptions(options);
  const setTarget = (target: HTMLElement | null) => setTargetElement(target);
  const openMenu = (target: HTMLElement | null, options: MenuOptionType[]) => {
    setMenuOptions(options);
    setTargetElement(target);
  };

  return (
    <MenuContext.Provider
      value={{
        isOpen: Boolean(targetElement),
        options: menuOptions,
        target: targetElement,
        setOptions,
        setTarget,
        openMenu,
      }}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
