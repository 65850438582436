import styled, { keyframes } from 'styled-components';

const BblFadInOut = keyframes`
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
`;

export const LoaderContainer = styled.div`
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1500;
  width: 100vw;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled.span`
  color: #fff;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &,
  &::after,
  &::before {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${BblFadInOut} 1.8s infinite ease-in-out;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
  }

  &::before {
    left: -4em;
    animation-delay: -0.32s;
  }
  &::after {
    left: 4em;
    animation-delay: 0.32s;
  }
`;
