import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';
import { Container } from './styles';

const PublicLayout = () => {
  const { user } = useSelector(authStore);

  if (user) {
    return <Navigate to={ROUTES.ADMIN} replace />;
  }

  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default PublicLayout;
