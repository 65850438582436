import { styled } from '@mui/material/styles';
import { TextField, Theme } from '@mui/material';

export const StyledInput = styled(TextField)(({ theme, fullWidth }: { theme: Theme; fullWidth: boolean }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& label': {
    display: 'none',
  },
  '& .MuiInputBase-root': {
    border: '1px solid #d1d1d1',
    borderRadius: 6,
  },
  '& .MuiInputBase-input': {
    borderRadius: 6,
    position: 'relative',
    color: theme.typography.body1.color,
    width: fullWidth ? '100%' : 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    fontFamily: theme.typography.fontFamily,
  },
  '& fieldset': {
    display: 'none',
  },
  '& .MuiFormHelperText-root': {
    margin: '3px 2px',
  },
  '& .MuiInputBase-multiline': {
    padding: 0,
  },
}));
