export const ROLE_TYPES = ['admin', 'user'] as const;
export type RoleType = (typeof ROLE_TYPES)[number];
export enum RoleEnum {
  USER = 1,
  ADMIN = 2,
}

export type AuthStoreType = {
  user: UserType | null;
  token: string;
};

export type UserType = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  role: RoleEnum;
};

export type JWTPayloadType = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  iat: number;
  exp: number;
  role: RoleType;
};

export type LoginRequestType = {
  email: string;
  password: string;
};

export type LoginResponseType = {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
};

export type ForgotPasswordRequestType = {
  email: string;
};

export type ForgotPasswordResponseType = {
  email: string;
};

export type ChangePasswordRequestType = {
  key: string;
  password: string;
  confirmPassword: string;
};

export type ChangePasswordResponseType = {
  message: string;
};
