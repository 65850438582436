import { useFormik } from 'formik';
import { SendEmailForm, validationSchema } from './schema';
import dayjs from 'dayjs';
import { UseSendEmailModalArgs } from './types';
import { useEffect } from 'react';
import { SupplierContact } from 'src/types/suppliers';
import { convertDayjsDatetimeToApiFormat } from 'src/utils/common/date';

const initialValues: SendEmailForm = {
  smtp: null,
  to: [],
  body: '',
  subject: '',
  isProgrammed: false,
  programDate: dayjs(),
};

export const useSendEmailModal = ({
  isOpen,
  defaultBody,
  defaultSubject,
  defaultSender,
  defaultRecipients,
  defaultScheduled,
  onSend,
}: UseSendEmailModalArgs) => {
  const form = useFormik<SendEmailForm>({
    initialValues,
    validationSchema,
    onSubmit: values =>
      onSend({
        ...values,
        smtpId: values.smtp.id,
        programDate:
          values.isProgrammed && values.programDate ? convertDayjsDatetimeToApiFormat(values.programDate) : null,
      }),
  });

  const handleReceivers = (values: (string | SupplierContact | undefined)[]) => {
    const receivers = values.filter(v => v).map(v => (typeof v === 'string' ? v : v?.email));
    form.setFieldValue('to', receivers);
  };

  useEffect(() => {
    form.resetForm({
      values: {
        ...initialValues,
        body: defaultBody || '',
        subject: defaultSubject || '',
        smtp: defaultSender || null,
        to: defaultRecipients || [],
        isProgrammed: !!defaultScheduled,
        programDate: defaultScheduled ? dayjs(defaultScheduled) : '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBody, defaultSubject, defaultSender, defaultRecipients]);

  useEffect(() => {
    if (!isOpen) form.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return { form, handleReceivers };
};
