import { Theme, styled } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers';

export const StyledInput = styled(DateTimePicker)(({ theme }: { theme: Theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& label': {
    display: 'none',
  },
  '& .MuiInputBase-root': {
    border: '1px solid #d1d1d1',
    borderRadius: 6,
  },
  '& .MuiInputBase-input': {
    borderRadius: 6,
    position: 'relative',
    color: theme.typography.body1.color,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    fontFamily: theme.typography.fontFamily,
  },
  '& fieldset': {
    display: 'none',
  },
  '& .MuiFormHelperText-root': {
    margin: '3px 2px',
  },
}));
