import { Loader, LoaderContainer } from './styles';
import { GlobalLoaderProps } from './types';

const GlobalLoader = ({ loading }: GlobalLoaderProps) => {
  if (!loading) return null;

  return (
    <LoaderContainer id="global-loader">
      <Loader />
    </LoaderContainer>
  );
};

export default GlobalLoader;
