import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'profile',
  {
    settings: 'Configuración',
    logout: 'Cerrar sesión',
  },
  true,
  false,
);

export default i18n;
