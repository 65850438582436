import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { SearchProps } from './types';
import Input from '../Input/Input';

const Search = ({ placeholder, value, disableUnderline, onChange }: SearchProps) => {
  return (
    <Input
      value={value}
      placeholder={placeholder || ''}
      name="search"
      fullWidth
      includeErrorSpace={false}
      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e.target.value, 'change')}
      sx={{ '& .MuiInputBase-root': { pr: 1 } }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ mr: 0 }}>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ ml: 0 }}>
            {value && (
              <IconButton size="small" onClick={() => onChange('', 'clear')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </InputAdornment>
        ),
        disableUnderline,
      }}
    />
  );
};

export default Search;
