import { DialogActions, Dialog as materialDialog } from '@mui/material';
import styled from 'styled-components';

export const ActionsContainer = styled(DialogActions)`
  &&& {
    justify-content: flex-end;
    gap: 24px;
    padding: 16px 24px;
  }
`;

export const Dialog = styled(materialDialog)`
  .MuiPaper-root {
    padding: 8px;
    max-width: 320px;
    min-width: 260px;
  }
`;
