import styled from '@emotion/styled';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const AppBarMenu = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="bottom-start" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    padding: 4,
    width: 160,
    borderRadius: 4,
    marginTop: '4px !important',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',

    '& .MuiTooltip-arrow': {
      display: 'none',
    },

    li: {
      borderRadius: 4,
    },
  },
}));

export default AppBarMenu;
