import { createSlice } from '@reduxjs/toolkit';
import { SettingsStore } from 'src/types/settings';
import { RootState } from '../store';

const initialState: SettingsStore = {};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
});

export const actions = settingsSlice.actions;

export const settingsStore = (state: RootState) => state.settings;

export default settingsSlice.reducer;
