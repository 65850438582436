import { configureStore } from '@reduxjs/toolkit';
import global from './global/global';
import auth from './auth/auth';
import settings from './settings/settings';
import users from './users/users';
import smtps from './smtps/smtps';
import orders from './orders/orders';
import deliveryNotes from './deliveryNotes/deliveryNotes';
import settlements from './settlements/settlements';
import documentNotifications from './documentNotifications/documentNotifications';
import clients from './clients/clients';
import client from './clients/client';
import suppliers from './suppliers/suppliers';
import supplier from './suppliers/supplier';
import invoices from './invoices/invoices';
import boxesControl from './boxesControl/boxesControl';
import documentManager from './documentManager/documentManager';
import paymentManager from './paymentManager/paymentManager';
import { apiSlice } from './api';

const reducer = {
  global,
  auth,
  settings,
  users,
  smtps,
  orders,
  deliveryNotes,
  settlements,
  documentNotifications,
  clients,
  client,
  suppliers,
  supplier,
  invoices,
  boxesControl,
  documentManager,
  paymentManager,
  [apiSlice.reducerPath]: apiSlice.reducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
