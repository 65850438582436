import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Smtp, SmtpsStore } from 'src/types/smtps';
import { RootState } from '../store';

const initialState: SmtpsStore = {
  smtpId: null,
  isOpen: false,
};

export const smtpsSlice = createSlice({
  name: 'smtps',
  initialState,
  reducers: {
    openSmtpDetail: (state: SmtpsStore, action: PayloadAction<Smtp['id'] | null>) => {
      const { payload } = action;
      state.isOpen = true;
      state.smtpId = payload;
    },
    closeSmtpDetail: (state: SmtpsStore) => {
      state.smtpId = null;
      state.isOpen = false;
    },
  },
});

export const actions = smtpsSlice.actions;

export const smtpsStore = (state: RootState) => state.smtps;

export default smtpsSlice.reducer;
