import { useDispatch, useSelector } from 'react-redux';
import { GlobalLoader } from 'src/components';
import { actions, authStore } from 'src/reducers/auth/auth';
import { selectLoading } from 'src/reducers/global/global';
import { getValue } from 'src/utils/common/localStorage';
import { TokenControllerProps } from './types';

const TokenController = ({ children }: TokenControllerProps) => {
  const isLoading = useSelector(selectLoading);
  const { token: storeToken } = useSelector(authStore);
  const dispatch = useDispatch();

  const token = getValue('token');
  if (token && token !== storeToken) {
    dispatch(actions.setUser(token));
  } else if (!token) {
    dispatch(actions.logout());
  }

  return (
    <>
      {children}
      <GlobalLoader loading={isLoading} />
    </>
  );
};

export default TokenController;
