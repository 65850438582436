import { MenuItem, Typography } from '@mui/material';
import AppBarMenu from './styles';
import { MenuProps } from './types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Menu = ({ option, children, onNavigate }: MenuProps) => {
  const { t } = useTranslation('header');
  const [open, setOpen] = useState(false);

  const handleClick = (path?: string) => () => {
    onNavigate(path)();
    setOpen(false);
  };

  return (
    <AppBarMenu
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disableInteractive={!option.options || !option.options.length}
      disableTouchListener={!option.options || !option.options.length}
      disableHoverListener={!option.options || !option.options.length}
      disableFocusListener={!option.options || !option.options.length}
      title={
        <>
          {option.options?.map(opt => (
            <MenuItem sx={{ padding: '10px 16px' }} onClick={handleClick(opt.path)}>
              <Typography noWrap> {t(opt.key)}</Typography>
            </MenuItem>
          ))}
        </>
      }>
      {children}
    </AppBarMenu>
  );
};

export default Menu;
