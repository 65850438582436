import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthStoreType } from 'src/types/auth';
import { RootState } from '../store';
import { decodeJWT } from 'src/utils/auth';

const initialState: AuthStoreType = {
  user: null,
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: AuthStoreType, action: PayloadAction<string>) => {
      const { payload } = action;
      state.token = payload;
      state.user = decodeJWT(payload);
    },
    logout: (state: AuthStoreType) => {
      state.user = null;
    },
  },
});

export const actions = authSlice.actions;

export const authStore = (state: RootState) => state.auth;

export default authSlice.reducer;
