import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertType, GlobalStoreType } from 'src/types/global';
import { RootState } from '../store';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

const initialState: GlobalStoreType = {
  snackbar: null,
  alert: {
    show: false,
    message: '',
    title: '',
    onCancel: () => {},
    onConfirm: () => {},
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showSuccessMessage: (state: GlobalStoreType, action: PayloadAction<string>) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        status: 'success',
      };
    },
    showErrorMessage: (state: GlobalStoreType, action: PayloadAction<string>) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        status: 'error',
      };
    },
    closeSnackbar: (state: GlobalStoreType) => {
      state.snackbar = {
        show: false,
        message: '',
        status: 'info',
      };
    },
    setAlert: (state: GlobalStoreType, action: PayloadAction<AlertType>) => {
      state.alert = action.payload;
    },
    closeAlert: (state: GlobalStoreType) => {
      state.alert.show = false;
    },
  },
});

export const globalActions = globalSlice.actions;

export const globalStore = (state: RootState) => state.global;

export default globalSlice.reducer;

const TABLE_ENDPOINTS = [
  'getClients',
  'getOrders',
  'getSmtps',
  'getUsers',
  'getSuppliers',
  'getSupplierAddresses',
  'getSupplierContacts',
  'getClientAddresses',
  'getClientContacts',
  'getDeliveryNotes',
  'getInvoices',
  'getSettlements',
  'getDocuments',
];

export const selectLoading = createSelector(
  (state: RootState) => state.api,
  api =>
    Object.values({ ...(api.queries || {}), ...(api.mutations || {}) }).some(
      query => query && !TABLE_ENDPOINTS.includes(query.endpointName || '') && query.status === QueryStatus.pending,
    ),
);
