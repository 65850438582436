import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions, authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';
import { removeItem } from 'src/utils/common/localStorage';

export const useProfile = () => {
  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);
  const { user } = useSelector(authStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElem(event.currentTarget);
  };

  const handleClose = () => setAnchorElem(null);

  const handleLogout = () => {
    dispatch(actions.logout());
    removeItem('token');
    navigate(ROUTES.LOGIN);
  };

  const handleSettingsClick = () => {
    handleClose();
    navigate(ROUTES.SETTINGS);
  };

  return {
    user,
    anchorElem,
    handleContextMenu,
    handleClose,
    handleLogout,
    handleSettingsClick,
  };
};
