import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentPath } from 'src/hooks/useCurrentPath';
import ROUTES from 'src/routes/routes';

export const useHeader = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const currentPath = useCurrentPath();
  const navigate = useNavigate();

  const handleDrawerToggle = () => setMobileOpen(prev => !prev);

  const isOptionActive = (path?: string) => {
    if (!path) return false;
    const homePaths = [ROUTES.ADMIN, ROUTES.INDEX];
    if (homePaths.includes(currentPath || '') && homePaths.includes(path)) return true;
    else if (currentPath?.includes(path)) return true;
    return false;
  };

  const handleNavigation = (path?: string) => () => {
    if (path) navigate(path);
  };

  return {
    mobileOpen,
    isOptionActive,
    handleDrawerToggle,
    handleNavigation,
  };
};
