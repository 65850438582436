import { MenuItem } from '@mui/material';
import styled from 'styled-components';

export const MenuButton = styled(MenuItem)`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  & span {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
`;
