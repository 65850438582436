import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Invoice, InvoiceList, InvoicesStore } from 'src/types/invoices';

const initialState: InvoicesStore = {
  invoices: null,
  isEditModalOpen: false,
  isDetailModalOpen: false,
  isSendModalOpen: false,
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    openEditModal: (state: InvoicesStore, action: PayloadAction<Invoice | InvoiceList | null>) => {
      state.invoices = action.payload ? [action.payload] : null;
      state.isEditModalOpen = true;
    },
    closeEditModal: (state: InvoicesStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.invoices = null;
      state.isEditModalOpen = false;
    },
    openDetailModal: (state: InvoicesStore, action: PayloadAction<Invoice | InvoiceList | null>) => {
      state.invoices = action.payload ? [action.payload] : null;
      state.isDetailModalOpen = true;
    },
    closeDetailModal: (state: InvoicesStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.invoices = null;
      state.isDetailModalOpen = false;
    },
    openSendModal: (state: InvoicesStore, action: PayloadAction<Invoice | InvoiceList | InvoiceList[] | null>) => {
      if (Array.isArray(action.payload)) state.invoices = action.payload;
      else state.invoices = action.payload ? [action.payload] : null;
      state.isSendModalOpen = true;
    },
    closeSendModal: (state: InvoicesStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.invoices = null;
      state.isSendModalOpen = false;
    },
  },
});

export const actions = invoicesSlice.actions;

export const invoicesStore = (state: RootState) => state.invoices;

export default invoicesSlice.reducer;
