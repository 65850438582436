import styled from 'styled-components';

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  & > svg {
    height: 18px;
  }
`;
