import styled from 'styled-components';

export const AutocompleteListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > * {
    width: 100%;
    text-align: left;
  }
`;

export const AttachedFilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 4px 0 16px 0;
`;

export const AttachedFile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 6px;
  width: fit-content;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  & > img {
    width: 40px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;
