import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PaymentManagerStore } from 'src/types/paymentManager';

const initialState: PaymentManagerStore = {
  isEditModalOpen: false,
};

export const paymentManagerSlice = createSlice({
  name: 'paymentManager',
  initialState,
  reducers: {
    openEditModal: (state: PaymentManagerStore) => {
      state.isEditModalOpen = true;
    },
    closeEditModal: (state: PaymentManagerStore) => {
      state.isEditModalOpen = false;
    },
  },
});

export const actions = paymentManagerSlice.actions;

export const paymentManagerStore = (state: RootState) => state.paymentManager;

export default paymentManagerSlice.reducer;
