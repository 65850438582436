export const saveValue = (key: string, value: string) => {
  if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const getValue = (key: string, shouldParse = false) => {
  if (shouldParse) {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item || '');
    } catch (e) {
      return {};
    }
  }
  return localStorage.getItem(key);
};

export const removeItem = (key: string) => localStorage.removeItem(key);
