import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axiosBaseQuery';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseURL: process.env.REACT_APP_API_URL || '' }),
  keepUnusedDataFor: 60, //  cache lifetime in seconds TODO: Buscar como configurarlo a nivel de cada tag/endpoint
  tagTypes: [
    'user',
    'smtp',
    'order',
    'client',
    'supplier',
    'client-address',
    'client-contact',
    'supplier-address',
    'supplier-contact',
    'invoice',
    'delivery-note',
    'settlement',
    'boxes-entry',
    'document-notification',
    'settings',
    'billable-order',
    'received-invoice',
    'issued-invoice',
    'document',
    'dashboard',
    'kilos',
  ], //TODO: Buscar manera de sacarlo a nivel del reducer correspondiente
  endpoints: () => ({}),
});
