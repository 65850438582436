import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Supplier, SuppliersStore } from 'src/types/suppliers';
import { RootState } from '../store';

const initialState: SuppliersStore = {
  supplierId: null,
  isOpen: false,
};

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    openSupplierDetail: (state: SuppliersStore, action: PayloadAction<Supplier['id'] | null>) => {
      const { payload } = action;
      state.isOpen = true;
      state.supplierId = payload;
    },
    closeSupplierDetail: (state: SuppliersStore) => {
      state.supplierId = null;
      state.isOpen = false;
    },
  },
});

export const actions = suppliersSlice.actions;

export const suppliersStore = (state: RootState) => state.suppliers;

export default suppliersSlice.reducer;
