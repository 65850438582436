import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UsersStore } from 'src/types/users';
import { RootState } from '../store';

const initialState: UsersStore = {
  userId: null,
  isOpen: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    openUserDetail: (state: UsersStore, action: PayloadAction<User['id'] | null>) => {
      const { payload } = action;
      state.isOpen = true;
      state.userId = payload;
    },
    closeUserDetail: (state: UsersStore) => {
      state.userId = null;
      state.isOpen = false;
    },
  },
});

export const actions = usersSlice.actions;

export const usersStore = (state: RootState) => state.users;

export default usersSlice.reducer;
