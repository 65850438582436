import { Typography } from '@mui/material';
import styled from 'styled-components';

export const iconStyles = {
  width: 22,
  zIndex: 1300,
  position: 'absolute',
  right: 0,
};

export const boxStyles = {
  display: 'flex',
  gap: 1,
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
};

export const InputText = styled(Typography)`
  flex: 1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
