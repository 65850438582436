import jwtDecode from 'jwt-decode';
import { JWTPayloadType, RoleEnum, UserType } from 'src/types/auth';
import { removeItem } from './common/localStorage';

export const decodeJWT = (token: string): UserType | null => {
  try {
    const payload = jwtDecode(token) as JWTPayloadType;
    return {
      ...payload,
      role: payload.role === 'admin' ? RoleEnum.ADMIN : RoleEnum.USER,
    };
  } catch (err) {
    removeItem('token');
    return null;
  }
};
