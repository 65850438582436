import { matchRoutes, useLocation } from 'react-router-dom';
import ROUTES from 'src/routes/routes';

const routes = Object.values(ROUTES).map(path => ({ path }));

export const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) || [];

  return route?.path;
};
