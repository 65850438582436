import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BoxesControlStore, BoxesEntry } from 'src/types/boxesControl';

const initialState: BoxesControlStore = {
  boxesEntries: null,
  isEditModalOpen: false,
};

export const boxesControlSlice = createSlice({
  name: 'boxesControl',
  initialState,
  reducers: {
    openEditModal: (state: BoxesControlStore, action: PayloadAction<BoxesEntry | null>) => {
      state.boxesEntries = action.payload ? [action.payload] : null;
      state.isEditModalOpen = true;
    },
    closeEditModal: (state: BoxesControlStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.boxesEntries = null;
      state.isEditModalOpen = false;
    },
  },
});

export const actions = boxesControlSlice.actions;

export const boxesControlStore = (state: RootState) => state.boxesControl;

export default boxesControlSlice.reducer;
